const navigation = {
	main: [
		{ name: "Home", href: "#" },
		{ name: "About Me", href: "#about-me" },
		{ name: "Projects", href: "#projects" },
		{ name: "Resume", href: "/resume.pdf" },
		{ name: "Contact", href: "#contact" },
	],
	social: [
		{
			name: "LinkedIn",
			href: "https://www.linkedin.com/in/kanetoomer/",
			icon: (props) => (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 24 24"
					{...props}>
					<path d="M22.23 0H1.77C.79 0 0 .77 0 1.72v20.56C0 23.23.79 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.21 0 22.23 0zM7.12 20.45H3.56V9H7.1v11.45h.02zm-1.78-12.9c-1.14 0-1.88-.78-1.88-1.75 0-.99.76-1.75 1.92-1.75s1.88.77 1.88 1.75c0 .98-.74 1.75-1.92 1.75zm13.48 12.9h-3.56v-5.86c0-1.47-.53-2.48-1.87-2.48-1.02 0-1.63.7-1.9 1.38-.1.25-.13.6-.13.95v6.01h-3.57V9h3.43v1.56h.05c.48-.75 1.33-1.83 3.24-1.83 2.36 0 4.13 1.54 4.13 4.85v6.86h-.02z" />
				</svg>
			),
		},
		{
			name: "GitHub",
			href: "https://github.com/kanetoomer",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "Email",
			href: "mailto:kanetoomer@gmail.com",
			icon: (props) => (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 24 24"
					{...props}>
					<path d="M12 13.065L2.4 6.3C2.72 5.965 3.18 5.75 3.7 5.75h16.6c.52 0 .98.215 1.3.55L12 13.065zm0 1.87l9.6-6.765v8.29c0 .84-.68 1.525-1.515 1.525H3.915A1.53 1.53 0 0 1 2.4 16.46v-8.29L12 14.935z" />
				</svg>
			),
		},
	],
};

export default function Footer() {
	return (
		<footer className="bg-white">
			<div className="mx-auto max-w-7xl overflow-hidden px-6 py-24 lg:px-8">
				<nav
					aria-label="Footer"
					className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12">
					{navigation.main.map((item) => (
						<div key={item.name} className="pb-6">
							<a
								href={item.href}
								target={item.name === "Resume" ? "_blank" : "_self"}
								rel={item.name === "Resume" ? "noopener noreferrer" : undefined}
								className="text-sm leading-6 text-gray-600 scroll-smooth hover:text-gray-900">
								{item.name}
							</a>
						</div>
					))}
				</nav>
				<div className="mt-10 flex justify-center space-x-10">
					{navigation.social.map((item) => (
						<a
							key={item.name}
							href={item.href}
							target="_blank"
							rel="noopener noreferrer"
							className="text-gray-400 scroll-smooth hover:text-gray-500">
							<span className="sr-only">{item.name}</span>
							<item.icon aria-hidden="true" className="h-6 w-6" />
						</a>
					))}
				</div>
				<p className="mt-10 text-center text-xs leading-5 text-gray-500">
					I built this site using React and Tailwind... :)
				</p>
			</div>
		</footer>
	);
}

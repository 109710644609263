import { CheckCircleIcon } from "@heroicons/react/20/solid";

const benefits = [
	"Competitive salaries",
	"Flexible work hours",
	"30 days of paid vacation",
	"Annual team retreats",
	"Benefits for you and your family",
	"A great work environment",
];

export default function About() {
	return (
		<div className="bg-gray-900 py-24 sm:py-32" id="about-me">
			<div className="relative isolate">
				<div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
					<div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
						<div className="w-full flex-auto">
							<h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
								About Me
							</h2>
							<p className="mt-6 text-lg leading-8 text-gray-300">
								Hi, I’m Kane Toomer, a software engineer with a Master’s in Data
								Science. I specialize in full-stack web development, scalable
								architecture, and API integration. With hands-on experience in
								building robust web applications and backend services, I’m
								passionate about solving complex technical problems and creating
								seamless user experiences. Throughout my academic and
								professional journey, I’ve honed my skills in modern programming
								languages, frameworks, and development tools, applying them to
								real-world software engineering challenges. As a recent
								graduate, I’m eager to contribute to a dynamic team where I can
								continue growing as a developer and make an impact by building
								innovative solutions. Feel free to explore my work and connect
								with me!
							</p>
							<p className="mt-6 text-xl font-bold tracking-tight text-white">
								My Skills:
							</p>
							<ul className="mt-6 text-gray-300 text-lg leading-8">
								<li>
									<b className="underline">Programming Languages</b>: Python,
									JavaScript, TypeScript
								</li>
								<li>
									<b className="underline">Backend</b>: Node.js, Express.js,
									MongoDB, PostgreSQL
								</li>
								<li>
									<b className="underline">Frontend</b>: React, Redux, HTML,
									CSS, Tailwind CSS
								</li>
								<li>
									<b className="underline">Development Tools</b>: Git, GitHub,
									CI/CD, Docker, Postman
								</li>
								<li>
									<b className="underline">Core Competencies</b>: Data
									Structures and Algorithms, API Integration, RESTful API
									development, Scalable Architecture, Agile & Scrum
									Methodologies
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div
					aria-hidden="true"
					className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl">
					<div
						style={{
							clipPath:
								"polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
						}}
						className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
					/>
				</div>
			</div>
		</div>
	);
}

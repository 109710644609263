import React from "react";

export default function CodeSnippet() {
	return (
		<div className="px-6 pt-6 text-white whitespace-pre-line break-words">
			<pre>
				<code>
					<p>
						<span className="text-pink-400">def</span>{" "}
						<span className="text-cyan-200">express_excitement</span>
						<span className="text-yellow-100">():</span>
					</p>
					<br />
					<p>
						<span className="ml-10 text-pink-400">excitement_level</span>{" "}
						<span className="text-yellow-100">=</span>{" "}
						<span className="text-blue-500">1,000</span>
					</p>
					<p>
						<span className="ml-10 text-pink-400">message</span>{" "}
						<span className="text-yellow-100">=</span>{" "}
						<span className="text-orange-300">"I'm so excited to find a</span>
						<br />
						<span className="text-orange-300 ml-20">
							software engineering job!"
						</span>
					</p>
					<br />
					<p>
						<span className="ml-10 text-blue-500">for</span>{" "}
						<span className="text-cyan-200">i</span>{" "}
						<span className="text-blue-500">in</span>{" "}
						<span className="text-cyan-200">range</span>
						<span className="text-yellow-100">(</span>
						<span className="text-blue-500">excitement_level</span>
						<span className="text-yellow-100">):</span>
					</p>
					<p>
						<span className="ml-20 text-pink-400">print</span>
						<span className="text-yellow-100">(</span>
						<span className="text-orange-300">f</span>
						<span className="text-yellow-100">"{"{"}</span>
						<span className="text-cyan-200">message</span>{" "}
						<span className="text-yellow-100">{"}"}</span>{" "}
						<span className="text-orange-300">{"!"}</span>
						<span className="text-yellow-100">{" * (i + 1)"}</span>
						<span>)</span>
					</p>
					<br />
					<p>
						<span className="text-cyan-200">express_excitement</span>
						<span className="text-yellow-100">()</span>
					</p>
				</code>
			</pre>
		</div>
	);
}

import foodly from "../../assets/foodly.png";
import dataImage from "../../assets/data.jpg";
export default function Projects() {
	return (
		<div className="py-32" id="projects">
			<div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
				<h2 className="text-base/7 font-semibold text-indigo-600">
					Featured Projects
				</h2>
				<p className="mt-2 max-w-lg text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
					Bringing ideas to life.
				</p>
				<div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-1">
					<div className="relative lg:col-span-3">
						<div className="absolute inset-px rounded-lg bg-white" />
						<div className="relative flex h-full flex-col overflow-hidden">
							<img
								alt="Screen Shot of Foodly Landing Page"
								src={foodly}
								className="h-80 object-none object-left-top"
							/>
							<div className="p-10 pt-4">
								<h3 className="text-sm/4 font-semibold text-indigo-600">
									Foodly (Full Stack E-Commerce Application)
								</h3>
								<p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">
									This was a quick but fun build.
								</p>
								<p className="mt-2 max-w-lg text-sm/6 text-gray-600">
									I built a full-stack e-commerce platform featuring a React
									frontend and admin panel, powered by a Node.js/Express backend
									with MongoDB for data management. The platform includes secure
									user authentication using JWT and bcrypt, with role-based
									access control for the admin panel. I integrated the Stripe
									API for smooth payment processing and implemented file uploads
									using multer. The admin panel allows efficient management of
									products, orders, and order statuses. I designed RESTful API
									endpoints for seamless CRUD operations on products, orders,
									and user profiles, enabling users to manage their cart,
									complete the checkout process, and view their order history.
								</p>
								<a
									href="https://foodly-project-frontend.onrender.com/"
									target="_blank"
									rel="noopener noreferrer"
									className="inline-flex rounded-md max-w-max bg-indigo-600 mt-10 px-3.5 py-2.5 text-sm font-semibold text-white text-center shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
									View Project
								</a>
							</div>
						</div>
						<div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
					</div>
					<div className="relative lg:col-span-3">
						<div className="absolute inset-px rounded-lg bg-white" />
						<div className="relative flex h-full flex-col overflow-hidden">
							<img
								alt=""
								src={dataImage}
								className="h-80 object-fill object-top rounded-t-md"
							/>
							<div className="p-10 pt-4">
								<h3 className="text-sm/4 font-semibold text-indigo-600">
									Customer Churn Prediction
								</h3>
								<p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">
									Regression Analysis & Predictive Modeling Project
								</p>
								<p className="mt-2 max-w-lg text-sm/6 text-gray-600">
									This project focuses on predicting customer churn using
									regression analysis and predictive modeling. By leveraging
									historical usage data, demographics, and behavioral patterns,
									the objective was to help businesses using subscription models
									reduce churn and increase revenue. Key steps included data
									preprocessing, exploratory data analysis (EDA), and feature
									engineering, followed by building models such as Logistic
									Regression and Random Forest. The models were evaluated using
									accuracy, precision, recall, F1-score, and AUC. Key findings
									revealed that shorter tenures, higher monthly charges, and
									shorter contract types are significant churn predictors.
									Logistic Regression achieved an 81.5% accuracy with an AUC of
									0.74, while Random Forest demonstrated potential for further
									improvement through hyperparameter tuning. This project
									highlights how predictive modeling can guide businesses in
									designing targeted strategies to improve customer retention.
								</p>
								<a
									href="https://github.com/kanetoomer/Customer-Churn-Prediction"
									target="_blank"
									rel="noopener noreferrer"
									className="inline-flex rounded-md max-w-max bg-indigo-600 mt-10 px-3.5 py-2.5 text-sm font-semibold text-white text-center shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
									View Project
								</a>
							</div>
						</div>
						<div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
					</div>
				</div>
			</div>
		</div>
	);
}
